import axios from "axios";

const BASE_URL = "https://easysb.org";

export function apiCall(method, path, data) {
  return new Promise((resolve, reject) => {
    axios.defaults.headers.common["Authorization"] = localStorage.jwtToken;
    return axios[method.toLowerCase()](BASE_URL + path, data)
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response);
      });
  });
}
