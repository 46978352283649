import React, { useState } from 'react';
import empty from 'is-empty';
import useWidth from '../utils/useWidth';
import withToast from '../utils/withToast';

import {Modal, TextField, Button} from '@material-ui/core';

import Loading from '../components/Graphics/Loading';

const Login = ({
  open,
  loginUser,
  addToast,
  onClose,
  resetPassword
}) => {
  const [state, setState] = useState({
    email: '',
    password: ''
  });
  const [loaded, setLoaded] = useState(true);
  const [forgot, setForgot] = useState(false);

  const onChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const onLoginUser = async () => {
    setLoaded(false);
    const resp = await loginUser(state);
    setLoaded(true);
    if(!resp.complete) {
      addToast(resp.error, {appearance: 'error', autoDismiss: true});
    } else {
      onClose();
    }
  }

  const onResetPassword = async () => {
    setLoaded(false);
    const resp = await resetPassword(state);
    setLoaded(true);
    if(resp.complete) {
      onClose();
    }
  }

  const emptyCheck = !empty(state.email) && !empty(state.password);

  return(
    <>
      {!loaded && (<Loading />)}
      <Modal onClose={onClose} open={open} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <div style={{width: "15rem", display: "flex", flexDirection: "column", background: "white", padding: "1rem", borderRadius: 6}}>
          <span style={{fontSize: "1.5rem"}}>{!forgot ? "Login" : "Forgot Password"}</span>
          <TextField
            type="text"
            variant="outlined"
            size="small"
            inputProps={{
              placeholder: "Email"
            }}
            name="email"
            value={state.email}
            onChange={onChange}
            style={{marginTop: "1rem"}}
          />
          {!forgot && (<TextField
            type="password"
            variant="outlined"
            size="small"
            inputProps={{
              placeholder: "Password"
            }}
            name="password"
            value={state.password}
            onChange={onChange}
            style={{marginTop: "1rem"}}
          />)}
          <span onClick={() => setForgot(true)} style={{marginTop: "0.4rem", textDecoration: "underline", cursor: 'pointer'}}>Forgot Password</span>
          {!forgot && (<Button onClick={onLoginUser} variant="contained" color="primary" style={{marginTop: "1rem"}} disabled={!emptyCheck}>Login</Button>)}
          {forgot && (<Button onClick={onResetPassword} variant="contained" color="primary" style={{marginTop: "1rem"}} disabled={empty(state.email)}>Submit</Button>)}
        </div>
      </Modal>
    </>
  );
}
 
export default withToast(Login);

