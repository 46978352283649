import React, { useState, useEffect } from 'react';
import toLocal from '../../utils/toLocal';
import moment from 'moment';

import sale from '../../images/sale.png';

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "22rem",
    background: "#e11b23",
    borderRadius: 6,
    padding: "2rem 1.5rem",
    color: "white",
    maxWidth: "calc(100% - 3rem)",
    position: "relative"
  },
  title: {
    fontSize: "2.5rem"
  },
  number: {
    fontSize: "1.75rem"
  },
  word: {
    fontSize: "1rem",
    fontWeight: 300
  },
  img: {
    position: "absolute",
    right: "-4rem",
    top: "-2rem",
    width: "8rem"
  }
}

const PriceCountdown = ({xs}) => {
  const base = 1636952399; // sun nov 14 2021 11:59p
  const twoweeks = 1210000;

  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const diff = moment.duration(toLocal((base + (Math.ceil(((now / 1000) - base) / twoweeks) * twoweeks)) * 1000).diff(moment()), 'milliseconds');

  return (<div style={styles.container}>
    {!xs && (<img style={styles.img} src={sale}/>)}
    <span style={styles.title}>Price goes up in...</span>
    <div style={{marginTop: "0.75rem", marginBottom: '0.25rem'}}>
      <span style={styles.number}>{diff.days()}</span>
      <span style={styles.word}> days </span>
      <span style={styles.number}>{diff.hours()}</span>
      <span style={styles.word}> hrs </span>
      <span style={styles.number}>{diff.minutes()}</span>
      <span style={styles.word}> min </span>
      <span style={styles.number}>{diff.seconds()}</span>
      <span style={styles.word}> sec </span>
    </div>
  </div>);
}

export default PriceCountdown;