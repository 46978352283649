import React from 'react';
import useWidth from '../utils/useWidth';

import { Divider, Grid } from '@material-ui/core';

const Terms = () => {
	const width = useWidth();

  const small = width === "xs" || width === "sm";
  const maxWidth = small ? "100%" : "60rem";

  return(
    <Grid xs={12} container item direction="column" alignItems="center" style={{background: "white", minHeight: 'calc(100vh - 4rem)'}}>
      <Grid xs={12} container item direction="column" style={{maxWidth: maxWidth, padding: small ? "1rem" :  ""}}>
        <span style={{fontSize: "2rem", marginTop: "1rem", fontWeight: 500}}>Terms of Use</span>
        <Divider style={{margin: "1rem 0"}}/>
        <span>
          EasySB is a tool meant to quickly export the questions and answers of a Smartbook to an excel sheet so flashcards can be created. EasySB is not responsible for any misuse of the product in an academic setting. EasySB does not own any of the content collected through the Smartbook and is not responsible for the improper distribution of the content.
        </span>
        <span style={{marginTop: "1rem"}}>
          EasySB only works with Smartbooks 2.0. Smartbooks 1.0 is not currently supported. The user is responsible for checking their version of Smartbooks before purchase.
        </span>
        <span style={{marginTop: "1rem"}}>
          Any academic misconduct that is conducted using EasySB is not the fault of the tool, but rather that of the user. The extension was built for a specific purpose - to help students record the content of a Smartbook quickly, and use the export to study or create flashcards. It is recommended that the user completes the Smartbook on their own the first time, and then clicks through the second time to capture the study data.
        </span>
        <span style={{marginTop: "1rem"}}>
          The EasySB extension is meant for personal use and should not be shared. The technology has precautions in place (the max number of device switches) so that users abide by the personal use guidelines. However, if it is detected that a user is sharing their login information with others, EasySB reserves the right to suspend any account.
        </span>
        <span style={{marginTop: "1rem"}}>
          If the extension ceases to function for any reason in the future, an email should be sent to <a href="mailto:easysb0101@gmail.com">easysb0101@gmail.com</a> and the issue will be resolved as soon as possible. By purchasing the extension, the user assumes the risk that McGraw Hill will alter their techology in a way that renders the extension useless, or that they will introduce their own export functionality. If this occurs within 30 days of the purchase date, a refund can be requested through <a href="mailto:easysb0101@gmail.com">easysb0101@gmail.com</a>, and a refund will be granted.
        </span>
        <span style={{marginTop: "1rem"}}>
          - EasySB
        </span>
      </Grid>
    </Grid>
  );
}
 
export default Terms;

