import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import empty from "is-empty";
import useWidth from "../utils/useWidth";
import { PRICE } from "../utils/constants";

import { Grid, Button, Divider } from "@material-ui/core";

import {
  loginUser,
  deactivateDevice,
  resetPassword,
} from "../store/actions/user";

import PaymentInfo from "../components/Payment/PaymentInfo";
import Login from "./Login";

const Home = ({ user, openlogin, loginUser, resetPassword }) => {
  const [login, setLogin] = useState(false);
  const width = useWidth();

  useEffect(() => {
    setLogin(openlogin);
  }, [openlogin]);

  const small = width === "xs" || width === "sm";
  const xs = width === "xs";
  const maxWidth = small ? "100%" : "60rem";

  return (
    <>
      <Login
        onClose={() => setLogin(false)}
        open={login && empty(user)}
        loginUser={loginUser}
        resetPassword={resetPassword}
      />
      <Grid
        xs={12}
        container
        item
        direction="column"
        alignItems="center"
        style={{ background: "white", minHeight: "calc(100vh - 4rem)" }}
      >
        <Grid
          xs={12}
          container
          item
          direction="column"
          style={{ maxWidth: maxWidth, padding: small ? "1rem" : "" }}
        >
          <span
            style={{ fontSize: "2rem", marginTop: "1rem", fontWeight: 500 }}
          >
            {empty(user) ? "EasySB" : "Your Account"}
          </span>
          {empty(user) && (
            <span style={{ marginTop: "0.5rem", fontSize: "0.9rem" }}>
              Purchase below
            </span>
          )}
          <Divider style={{ margin: "1rem 0" }} />
          {empty(user) && (
            <>
              <span style={{ fontSize: "1.5rem" }}>${PRICE / 100}</span>
              <span style={{ marginTop: "0.5rem", fontWeight: 300 }}>
                Unlimited use, one device
              </span>
              <PaymentInfo style={{ marginTop: "1rem" }} />
            </>
          )}
          {!empty(user) && (
            <>
              <span>Email: {user.email}</span>
              <span
                style={{
                  marginTop: "4rem",
                  fontSize: "1.1rem",
                  fontWeight: 400,
                }}
              >
                Download extension
              </span>
              <Divider style={{ margin: "0.5rem 0" }} />
              <span style={{ marginTop: "0.5rem" }}>
                Follow the <a href="/guide">guide</a> to use the following files
                to install the extension on your computer. Note that you must be
                using Chrome.
              </span>
              <a href="/easysb.zip" download>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ width: "15rem", marginTop: "0.5rem" }}
                >
                  Download Extension
                </Button>
              </a>
              <span
                style={{
                  marginTop: "4rem",
                  fontSize: "1.1rem",
                  fontWeight: 400,
                }}
              >
                Referrals
              </span>
              <Divider style={{ margin: "0.5rem 0" }} />
              <span style={{ marginTop: "0.5rem" }}>
                {user.referrals} people have inputted your email as a referral.
              </span>
              <span
                style={{
                  fontSize: "0.8rem",
                  marginTop: "0.25rem",
                  width: xs ? "100%" : "50%",
                }}
              >
                Refer <u>3 people</u> and you will have your license fully
                refunded! Make sure the person you are referring knows your
                exact email that you signed up with. If you have referred 3
                people, your refund should appear within 5 - 10 days.
              </span>
              <span style={{ margin: "7rem 0 4rem" }}>
                Email questions to{" "}
                <a
                  href="mailto:easysb0101@gmail.com"
                  style={{ color: "black" }}
                >
                  easysb0101@gmail.com
                </a>
              </span>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  loginUser,
  resetPassword,
})(Home);
