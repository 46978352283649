import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import empty from 'is-empty';
import useWidth from '../../utils/useWidth';

import {Grid, Button, ButtonBase, SwipeableDrawer, ListItem} from '@material-ui/core';
import {Menu} from '@material-ui/icons';

import logo from '../../images/logo.png';

const Navbar = ({user, theme, logoutUser}) => {
	const [open, setOpen] = useState(false);

	const width = useWidth();

  const small = width === "xs" || width === "sm";
	const xs = width === "xs";
  const maxWidth = small ? "100%" : "60rem";

	return(
		<Grid xs={12} container item direction="column" alignItems="center" style={{zIndex: 100}}>
			<Grid container item xs={12} style={{padding: "1.2rem 1rem 1rem", boxShadow: `0 3px 5px #eee`, justifyContent: "center", zIndex: 98}}>
				<Grid container item justify="space-between" alignItems="center" xs={12} style={{maxWidth: maxWidth}}>
					<Link to="/" style={{display: 'flex', alignItems: 'center'}}><img src={logo} style={{height: xs ? "2rem" : "3rem"}} alt=""/></Link>
					{!small && (<div style={{display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
						{empty(user) && (<Link to="/" style={{marginRight: "1.2rem"}}>
							<span style={{margin: "0 0.25rem 0 0.15rem", fontSize: "0.9rem", fontWeight: 500, color: theme.primary.main}}>BUY</span>
						</Link>)}
						<Link to="/guide" style={{marginRight: "1.2rem"}}>
							<span style={{margin: "0 0.25rem 0 0.15rem", fontSize: "0.9rem", fontWeight: 500, color: theme.primary.main}}>GUIDE</span>
						</Link>
						{empty(user) && (<Link to="/login">
							<Button variant="outlined" color="primary" size="small">
								Sign In
							</Button>
						</Link>)}
						{!empty(user) && (<>
							<Link to="/" style={{marginRight: "1.2rem"}}>
								<span style={{margin: "0 0.25rem 0 0.15rem", fontSize: "0.9rem", fontWeight: 500, color: theme.primary.main}}>ACCOUNT</span>
							</Link>
							<Button onClick={logoutUser} variant="outlined" color="primary" size="small" style={{display: "flex", alignItems: "center"}}>
								<span style={{margin: "0 0.25rem 0 0.15rem"}}>LOGOUT</span>
							</Button>
						</>)}
					</div>)}
					{small && (<ButtonBase onClick={() => setOpen(true)}>
						<Menu style={{fontSize: "1.5rem", color: theme.primary.main}} />
					</ButtonBase>)} 
					{small && (
						<SwipeableDrawer
							anchor="right"
							open={open}
							onClose={() => setOpen(false)}
							onOpen={() => setOpen(true)}
						>
							<Grid direction="column" style={{height: "100%", paddingTop: "0.5rem"}}>
								{empty(user) && (<Link to="/">
									<ListItem button style={{minWidth: "15rem", padding: "0.7rem 1.2rem"}}>
										<span style={{fontWeight: 700, fontSize: "0.875rem", lineHeight: 1.75, color: theme.primary.main}}>BUY</span>
									</ListItem>
								</Link>)}
								<Link to="/guide">
									<ListItem button style={{minWidth: "15rem", padding: "0.7rem 1.2rem"}}>
										<span style={{fontWeight: 700, fontSize: "0.875rem", lineHeight: 1.75, color: theme.primary.main}}>GUIDE</span>
									</ListItem>
								</Link>
								{empty(user) && (<Link to="/login">
									<ListItem button style={{minWidth: "15rem", padding: "0.7rem 1.2rem"}}>
										<span style={{fontWeight: 700, fontSize: "0.875rem", lineHeight: 1.75, color: theme.primary.main}}>SIGN IN</span>
									</ListItem>
								</Link>)}
								{!empty(user) && (<Link to="/">
									<ListItem button style={{minWidth: "15rem", padding: "0.7rem 1.2rem"}}>
										<span style={{fontWeight: 700, fontSize: "0.875rem", lineHeight: 1.75, color: theme.primary.main}}>ACCOUNT</span>
									</ListItem>
								</Link>)}
								{!empty(user) && (<Link to={`/account`}>
									<ListItem button style={{minWidth: "15rem", padding: "0.7rem 1.2rem"}}>
										<Button onClick={logoutUser} variant="outlined" color="primary" size="small" style={{display: "flex", alignItems: "center"}}>
											<span style={{margin: "0 0.25rem 0 0.15rem"}}>LOGOUT</span>
										</Button>
									</ListItem>
								</Link>)}
							</Grid>
						</SwipeableDrawer>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}
 
export default Navbar;