import React from 'react';
import useWidth from '../../utils/useWidth';

import { Grid, Divider } from '@material-ui/core';

const Footer = ({ theme }) => {
	const width = useWidth();

  const small = width === "xs" || width === "sm";
  const maxWidth = small ? "100%" : "60rem";

	return(
		<Grid xs={12} container item direction="column" alignItems="center" style={{background: theme.background.main, zIndex: 100}}>
			<Divider style={{width: "100%"}}/>
			<div style={{zIndex: 99, maxWidth: maxWidth, width: small ? "calc(100% - 2rem)" : "100%", display: "flex", alignItems: "center", justifyContent: "space-between", padding: small ? "1rem" : "0.7rem"}}>
				<a href="mailto:easysb0101@gmail.com" style={{fontWeight: 300, fontSize: "0.8rem", flex: 1, textAlign: "right", paddingTop: "0.2rem", color: "black"}}>easysb0101@gmail.com</a>
			</div>
		</Grid>
	);
}
 
export default Footer;