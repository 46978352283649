import React from "react";
import { Link } from "react-router-dom";
import useWidth from "../utils/useWidth";

import { Grid, Divider } from "@material-ui/core";

import home from "../images/home.png";
import download from "../images/download.gif";
import manageExtensions from "../images/manage-extensions.gif";
import devMode from "../images/dev-mode.gif";
import loadUnpacked from "../images/load-unpacked.gif";
import pin from "../images/pin.gif";

const Guide = () => {
  const width = useWidth();

  const small = width === "xs" || width === "sm";
  const xs = width === "xs";
  const maxWidth = small ? "100%" : "60rem";

  return (
    <Grid
      xs={12}
      container
      item
      direction="column"
      alignItems="center"
      style={{
        background: "white",
        minHeight: "calc(100vh - 4rem)",
        overflow: "none",
      }}
    >
      <Grid
        xs={12}
        container
        item
        direction="column"
        style={{ maxWidth: maxWidth, padding: small ? "1rem" : "" }}
      >
        <span style={{ fontSize: "2rem", marginTop: "1rem", fontWeight: 500 }}>
          EasySB
        </span>
        <span style={{ marginTop: "0.5rem" }}>
          A guide to using the service.
        </span>
        <Divider style={{ margin: "1rem 0" }} />
        <Grid xs={12} container item>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/yPukg_KpvxA"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Grid>
        <ol style={{ width: small ? "" : "75%", paddingBottom: "3rem" }}>
          <li>
            Download Google Chrome{" "}
            <a href="https://www.google.com/chrome/" target="_none">
              here
            </a>{" "}
            if you aren't using it already. EasySB only works with Chrome on a
            desktop-grade operating system (does not work on iOS/iPadOS/etc).
          </li>
          <li style={{ marginTop: "1rem" }}>
            If you haven't already, purchase a license for EasySB{" "}
            <Link to="/">here.</Link>{" "}
            <i>
              NOTE: EasySB only works with Smartbooks 2.0, the version pictured
              in the video above.
            </i>
          </li>
          <li style={{ marginTop: "1rem" }}>
            <a href="/login">Login to your account</a> and download the EasySB
            Chrome extension from your <i>Account</i> page.
          </li>
          <img
            src={download}
            alt=""
            style={{
              width: "40rem",
              maxWidth: "80vw",
              marginTop: "0.5rem",
              border: "1px solid black",
            }}
          />
          <li style={{ marginTop: "1rem" }}>
            Navigate to the extensions button which is a puzzle piece in the top
            right of your browser. Click Manage Extensions.
          </li>
          <img
            src={manageExtensions}
            alt=""
            style={{
              width: "40rem",
              maxWidth: "80vw",
              marginTop: "0.5rem",
              border: "1px solid black",
            }}
          />
          <li style={{ marginTop: "1rem" }}>
            Ensure that <i>Developer Mode</i> is enabled.
          </li>
          <img
            src={devMode}
            alt=""
            style={{
              width: "40rem",
              maxWidth: "80vw",
              marginTop: "0.5rem",
              border: "1px solid black",
            }}
          />
          <li style={{ marginTop: "1rem" }}>
            Unzip the downloaded file by double clicking on it. You should see a
            folder called easysb. Click <i>Load Unpacked</i> and select the
            easysb folder.
          </li>
          <img
            src={loadUnpacked}
            alt=""
            style={{
              width: "40rem",
              maxWidth: "80vw",
              marginTop: "0.5rem",
              border: "1px solid black",
            }}
          />
          <li style={{ marginTop: "1rem" }}>
            Pin extension to your Chrome browser bar.
          </li>
          <img
            src={pin}
            alt=""
            style={{
              width: "15rem",
              marginTop: "0.5rem",
              border: "1px solid black",
            }}
          />

          <li style={{ marginTop: "1rem" }}>
            You are ready to use EasySB! When starting a Smartbook, navigate to
            the first question and click the EasySB extension in the extension
            bar. Then hit the Reset button. Hit Next as questions appear and
            EasySB will do the rest. As the extension learns the questions, it
            will get more correct. Once it works its way through the Smartbook,
            click the export button at the bottom to export the questions and
            answers so you can study!
          </li>
          <img
            src={home}
            alt=""
            style={{
              width: "15rem",
              marginTop: "0.5rem",
              border: "1px solid black",
            }}
          />
          <li style={{ marginTop: "1rem" }}>
            You can use the excel export to directly create flashcards in
            Quizlet. Just copy and paste from excel. Tutorial below.
          </li>
          <iframe
            style={{ marginTop: "0.5rem" }}
            width={xs ? "300" : "560"}
            height="315"
            src="https://www.youtube.com/embed/cDaEFXv-nX4"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </ol>
      </Grid>
    </Grid>
  );
};

export default Guide;
