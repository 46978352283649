import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { STRIPE_PUBLIC_KEY } from '../../utils/constants';
import useWidth from '../../utils/useWidth';

import { Grid } from '@material-ui/core';

import CardFormV2 from './CardFormV2';
import PriceCountdown from './PriceCountdown';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const PaymentInfo = ({style}) => {
  const width = useWidth();
  const xs = width === "xs";
  const small = width === "xs" || width === "sm";

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({stripe, elements}) => (<Grid container item xs={12} style={{width: "100%", flexDirection: small ? "column-reverse" : "row", flexWrap: "wrap"}}>
          <Grid container item direction="column" xs={12} md={6}>
            <CardFormV2 style={style} stripe={stripe} elements={elements}/>
            <iframe style={{marginTop: "3rem"}} width={xs ? "300" : "560"} height="315" src="https://www.youtube.com/embed/yPukg_KpvxA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span style={{margin: '7rem 0 4rem'}}>Email questions to <a href="mailto:easysb0101@gmail.com" style={{color: "black"}}>easysb0101@gmail.com</a></span>
          </Grid>
          <Grid container item direction="column" xs={12} md={6} style={{padding: !small ? "0 0 0 2rem" : "1rem 0"}}>
            <PriceCountdown xs={xs} />
          </Grid>
        </Grid>)}
      </ElementsConsumer>
    </Elements>
  );
}

export default PaymentInfo;