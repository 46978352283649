import React from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureStore } from '../store';
import jwt_decode from 'jwt-decode';

import setAuthToken from '../utils/setAuthToken';
import { setCurrentUser } from '../store/actions/user';

import Main from './Main';
import Toast from '../components/Graphics/Toast';

import '../css/overall.css';

const store = configureStore();

if(localStorage.jwtToken) {

  try {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));

  } catch(e) {
    console.log('invalid token');
  }
  
}

const App = () => {
  return (
    <ToastProvider placement="bottom-center" components={{ Toast: Toast }}>
      <Provider store={store}>
        <Router>
          <div className="content-wrap">
            <Main />
          </div>
        </Router>
      </Provider>
    </ToastProvider>
  );
}

export default App;
