import React, { useEffect } from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import empty from 'is-empty';

import {Grid, ThemeProvider} from '@material-ui/core';
import {createMuiTheme} from '@material-ui/core/styles';

import {getUser, logoutUser} from '../store/actions/user';

import Footer from '../components/Nav/Footer';
import Navbar from '../components/Nav/Navbar';
import Home from './Home';
import Guide from './Guide';
import Terms from './Terms';

const Main = ({
	user,
	getUser,
	logoutUser
}) => {

	useEffect(() => {
		async function loadUser() {
			if(!empty(user.iat)) {
				await getUser(user);
			}
		}
		loadUser();
	}, []);

	const theme = createMuiTheme({
		typography: {
			fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
		},
		palette: {
			primary: {
				main: "#e11b23",
				contrastText: "#fff"
			},
			secondary: {
				main: "#fff",
				contrastText: "#000"
			}
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Grid container item direction="column" xs={12}>
				<Navbar logoutUser={logoutUser} user={user} theme={theme.palette}/>
				<Switch>
					<Route exact path='/' component={() => <Home theme={theme.palette}/>} />
					<Route exact path='/login' component={() => <Home theme={theme.palette} openlogin/>} />
					<Route exact path='/guide' component={() => <Guide theme={theme.palette} user={user}/>} />
					<Route exact path='/terms' component={() => <Terms theme={theme.palette} />}/>
					<Route path='*' component={() => <Home theme={theme.palette} />} />
				</Switch>
				<Footer theme={theme.palette} />
			</Grid>
		</ThemeProvider>
	)
}

const mapStateToProps = state => ({
	user: state.user
});

export default connect(mapStateToProps,{ getUser, logoutUser })(withRouter(Main));


