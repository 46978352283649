import React, { useState } from 'react';
import {Link, withRouter} from 'react-router-dom';
import {CardElement} from '@stripe/react-stripe-js';
import {connect} from "react-redux";
import empty from 'is-empty';
import withToast from '../../utils/withToast';

import {Button, TextField, InputAdornment} from '@material-ui/core';

import {registerUser} from '../../store/actions/user';

import Loading from '../Graphics/Loading';

const CardFormV2 = ({
  stripe, 
  elements, 
  registerUser, 
  style,
  addToast
}) => {

  const [state, setState] = useState({
    email: '',
    password: '',
    referral: '',
    school: ''
  });
  const [loading, setLoading] = useState(false);

  const onChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if(empty(state.email) || empty(state.password)) {
      addToast('You must enter an email and a password.', {appearance: 'error', autoDismiss: true});
    } else {
      setLoading(true);

      const cardElement = elements.getElement(CardElement);

      stripe.createSource(cardElement, {
        type: 'card',
        currency: 'usd',
        owner: {
          email: state.email
        }
      }).then(async result => { 
        try {
          const resp = await registerUser({source: result.source.id, ...state}); 
          setLoading(false);
          if(!resp.complete) {
            addToast(resp.error, {appearance: 'error', autoDismiss: true});
          }
        } catch(e) {
          setLoading(false);
          addToast('Invalid input. Check your credit card details.', {appearance: 'error', autoDismiss: true});
        }
      })
      .catch(function(err){
        try {
          addToast(err.error.message, {appearance: 'error', autoDismiss: true});
        } catch(e) {
          addToast('Invalid input. Check your credit card details.', {appearance: 'error', autoDismiss: true});
        }
      });
    }
  };

  return (
    <form style={{width: "100%", position: "relative", display: "flex", flexDirection: "column", ...style}}>
      <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
        {loading && (<Loading />)}
      </div>
      <TextField
        fullWidth
        type="text"
        name="email"
        style={{marginTop: "0.5rem"}}
        onChange={onChange}
        value={state.email}
        variant="outlined"
        placeholder="Email"
        size="small"
        InputProps={{ 
          startAdornment: (
            <InputAdornment position="start" style={{margin: "0", marginRight: "7px"}}>
              <div style={{display: "flex", alignItems: "center", fontWeight: 300}}><span>Email</span></div>
            </InputAdornment>
          ),
          style: {
            padding: "0.25rem 1rem"
          }
        }}
      />
      <TextField
        fullWidth
        type="password"
        name="password"
        style={{marginTop: "0.5rem"}}
        onChange={onChange}
        value={state.password}
        variant="outlined"
        size="small"
        placeholder="Password"
        InputProps={{ 
          startAdornment: (
            <InputAdornment position="start" style={{margin: "0", marginRight: "7px"}}>
              <div style={{display: "flex", alignItems: "center", fontWeight: 300}}><span>Password</span></div>
            </InputAdornment>
          ),
          style: {
            padding: "0.25rem 1rem"
          }
        }}
      />
      <TextField
        fullWidth
        name="school"
        style={{marginTop: "0.5rem"}}
        onChange={onChange}
        value={state.school}
        variant="outlined"
        size="small"
        placeholder="University / College"
        InputProps={{ 
          startAdornment: (
            <InputAdornment position="start" style={{margin: "0", marginRight: "7px"}}>
              <div style={{display: "flex", alignItems: "center", fontWeight: 300}}><span>School</span></div>
            </InputAdornment>
          ),
          style: {
            padding: "0.25rem 1rem"
          }
        }}
        inputProps={{
          maxLength: 100
        }}
      />
      <TextField
        fullWidth
        name="referral"
        style={{marginTop: "0.5rem"}}
        onChange={onChange}
        value={state.referral}
        variant="outlined"
        size="small"
        placeholder="myfriend@gmail.com"
        InputProps={{ 
          startAdornment: (
            <InputAdornment position="start" style={{margin: "0", marginRight: "7px"}}>
              <div style={{display: "flex", alignItems: "center", fontWeight: 300}}><span>Referral Email</span></div>
            </InputAdornment>
          ),
          style: {
            padding: "0.25rem 1rem"
          }
        }}
      />
      <p style={{fontWeight: 300, margin: "0.5rem 0 0.75rem", fontSize: "0.8rem"}}>
        &#9432; If a friend referred you, list their email here! Refer <u>3 people</u> and you will have your license fully refunded! Make sure you get their email exactly correct.
      </p>
      <fieldset style={{borderRadius: "3px", border: "1px solid rgba(0,0,0,0.24)", padding: "0", backgroundColor: "white", margin: 0, marginLeft: "-0.5px", marginTop: "0.5rem"}}>
        <div style={{padding: "0.75rem 1rem"}}>
          <CardElement options={{
            style: {
              base: {
                fontSize: '16px',
                width: "100%",
                fontFamily: "Kanit, sans-serif"
              },
              invalid: {
                iconColor: 'red',
                color: 'red',
              },
            }}} 
          />
        </div>
      </fieldset>
      <span style={{marginTop: "0.5rem", fontSize: "0.75rem", width: "100%"}}>By purchasing, you agree to the <Link to="/terms">Terms of Use</Link>.</span>
      <Button onClick={handleSubmit} color="primary" size="large" variant="contained" fullWidth style={{marginTop: '0.5rem'}}>Purchase</Button>
    </form>
  );
}

export default connect(null, { registerUser })(withRouter(withToast(CardFormV2)));
