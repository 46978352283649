import React from 'react';
import Lottie from 'lottie-react-web';

import loading from '../../images/loading';

import { Modal } from '@material-ui/core';

const Loading = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true, 
		animationData: loading
	};

	return(
		<Modal open style={{display: "flex", justifyContent: "center", alignItems: "center", zIndex: 100000}}>
			<div style={{borderRadius: "50%"}}>
				<Lottie
					options={defaultOptions}
					width="120px"
					height="120px"
					speed={1}
				/>
			</div>
		</Modal>
	);
}
 
export default Loading;